import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo-removebg-preview.png';
import './ForgotPassword.scss';
import { Button, Card, Checkbox, Form, Input, Space, App, Flex } from 'antd';
import React, { useState } from 'react';
import { HiUsers } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { setToken, setUser } from '../../../Store/user/UserSlice';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { authForget } from '../../../Network/auth';
import { FaChevronLeft } from 'react-icons/fa';
const ForgotPassword = () => {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingAuth, setLoadingAuth] = useState(false);
  const { mutate: onCheckEmail } = useMutation({
    mutationKey: ['checkEmail'],
    mutationFn: (body) => authForget(body),
    onSuccess: (res) => {
      setLoadingAuth(false);
      console.log('Login  res:', res);
      if (!res) {
        return message.error('Please check your email');
      } 
      navigate('/reset-password');
      message.success('email sent successfully');
    },
    onError: (error) => {
      setLoadingAuth(false);
      console.log('Login  error:', error);
      message.error(
        'Please check your email and try again.'
      );
    },
  });
  const onFinish = (values) => {
    // console.log('Success:', values);
    setLoadingAuth(true);
    onCheckEmail({
      email: values.email,
      });
  };
  return (
    <div
      className="login"
      style={{
        justifyContent:'center',
      }}
    >
        {/* <div className="left">
          <img src={logo} alt="logo" />
        </div> */}
      {/* <div className="right"> */}
        <Card
          className="login-card"
          style={{
            background:'#f9f6f6',
          }}
        >
            <div className=""
              style={{
                display:'flex',
                alignItems:'start',
                justifyContent:'space-between',
                flexDirection:'column',
                // gap:'10px',
                marginBottom:'20px',
              }}
            >
            <Link to="/login" 
              style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
              }}
            >
               <FaChevronLeft />
              Back 
              </Link>
              <Link to="/login" className="logo-holder"
                style={{
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center',
                  width: "200px",
                //   height: "80px",
                  margin:'20px auto',
                }}
              >
                <img src={logo} alt="logo" style={{width:'100%', height:'100%'}} />
              </Link>
              {/* <p className="login-title"
              >
              Forgot Password?
              </p> */}
              <p className="login-sub"
                style={{
                  textAlign:'center',
                  color:'#333',
                  fontSize:'14px',
                //   fontWeight:'bold',

                }}
              >
              Please enter the email to send an SMS containing the verification code first
              </p>
            </div>
          <Form
            layout="vertical"
            name="login-form"
            onFinish={onFinish}
            form={form}
            className="login-form"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingAuth}
                block
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </Card>
      {/* </div> */}
    </div>
  );
};

export default ForgotPassword;
