import logo from '../assets/images/mainLogo.png';
import logoLight from '../assets/images/logo-removebg-preview.png';
import { Menu } from 'antd';
import {BranchesOutlined} from '@ant-design/icons';
import { GrDashboard } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';import { useSelector } from 'react-redux';
import { AiFillContainer } from "react-icons/ai";
import { BsBookFill, BsClipboardCheckFill } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';



function SidebarMenuAdmin({collapsed,t}) {
  const navigate = useNavigate();
    const { darkMode } = useSelector((state) => state.user);
  const getItem = (key, label, children, icon) => ({
    key,
    label,
    children,
    icon,
  });
  const items = [
    getItem('admin', t.dashboard, null, <GrDashboard className="navbar-icon" />),
    
    getItem('admin/branches', t.branches, null, <BranchesOutlined  className="navbar-icon" />),
    // getItem('admin/plans', t.plans, null, <AiFillContainer  className="navbar-icon" />),
    // getItem('admin/features', t.features, null, <BsBookFill   className="navbar-icon" />),
    // getItem('admin/subfeatures', t.subfeatures, null, <BsClipboardCheckFill    className="navbar-icon" />),
    getItem('admin/customers', t.customers, null, <FaUsers className="navbar-icon" />),
    // getItem('admin/editor', t.Editor, null, <FaUsers className="navbar-icon" />),
    
  ];
  return (
    <div>
      <div
        className="logo"
        onClick={() => {
          navigate('/admin');
        }}
      >
        <img
          src={darkMode ? logo : logoLight}
          alt="logo"
          style={{
            width: '80%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            cursor: 'pointer',
          }}
        />
      </div>
      <Menu
        mode="inline"
        // mode="horizontal"
        items={items}
        defaultSelectedKeys={[
          window.location.pathname === '/'
            ? ''
            : window.location.pathname.split('/')[1],
        ]}
        defaultOpenKeys={['sub2']}
        onClick={({ key }) => {
          console.log('getItem  key:', key);
          navigate('/' + key);
        }}
      />
    </div>
  );
}

export default SidebarMenuAdmin;
