import React from 'react'
import  './DashboardAdmin.scss'
import Heading from '../../Components/Common/Heading/Heading'
import { Card, Col, Flex, Row, Typography } from 'antd'
import img1 from '../../assets/images/check.png'
import img2 from '../../assets/images/bookmarks.png'
import img3 from '../../assets/images/bulb.png'
import { FaCheckSquare } from 'react-icons/fa'
import { BsBookmarksFill } from 'react-icons/bs'
import { HiLightBulb } from 'react-icons/hi'
import { queryKeys } from '../../services/react-query/queryKeys'
import { getBranches } from '../../Network/branches'
import { useQuery } from '@tanstack/react-query'
const {Text} = Typography
const DashboardAdmin = ({t}) => {
  const { data: branches} = useQuery({
    queryKey: [queryKeys.getBranches],
    queryFn: () => getBranches(),
    keepPreviousData: true,
  });
  const data = [
    {
      id: 1,
      title: 'New Users',
      count:"12",
      icon:<FaCheckSquare size={25} color='#6759d1' />,
      img:img1,
      bg:"#6759d1"
    },
    {
      id: 2,
      title: 'Branches',
      count:branches?.length,
      icon:<BsBookmarksFill size={25} color='#21262E' />,
      img:img2,
      bg:"#21262E"
    },
    {
      id: 3,
      title: 'Orders',
      count:"903",
      icon:<HiLightBulb size={25} color='#40a7e2'  />,
      img:img3,
      bg:"#40a7e2"
    } 
  ]
  return (
    <div className="dashboard-admin">
      <Heading title={t.dashboard} t={t} />
      <Row gutter={[16, 16]} className='dashboard-row'
        style={{marginTop: 20}}
      >
        {
          data.map((item) => (
            <Col sm={12} xs={24} md={8} lg={8} xxl={8} key={item?.id}>
              <Card className="dashboard-card" 
              style={{background: item?.bg}}
              >
                <div className="dashboard-card-img">
                  <img src={item?.img} alt="dashboard" />
                </div>
                <Flex align='center' gap={'10px'} >
                <div className="dashboard-card-icon">
                  {item?.icon}
                </div>
                <div className="dashboard-card-info">
                <div className="dashboard-card-count">
                  <h2 className='dashboard-card-count-text'>{item?.count}</h2>
                </div>
                <div className="dashboard-card-title">
                  <h3 className='dashboard-card-title-text'>{item?.title}</h3>
                </div>
                </div>
                </Flex>
              </Card>

            </Col>
          ))
        }
      </Row>
    </div>
  )
}

export default DashboardAdmin