import { combineReducers, configureStore } from '@reduxjs/toolkit';
import  userSlice  from './user/UserSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';



const rootPersistConfig = {
    key: 'root',
    storage,
    // whitelist: ['user'],
};


const rootReducer = combineReducers({
  user: userSlice,
});
const persistedReducer = persistReducer(rootPersistConfig, rootReducer);


 const store = configureStore({
  reducer: persistedReducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});


export default store;
