// import logo from "../assets/images/logo-removebg-preview.png"
// import logoLight from '../assets/images/mainLogoLight.jpeg';
import logo from '../assets/images/mainLogo.png';
import logoLight from '../assets/images/logo-removebg-preview.png';
import logo2 from '../assets/images/logo-removebg-preview(1).png';
import { Menu } from 'antd';
import { GrDashboard } from 'react-icons/gr';
import { IoAnalyticsOutline, IoChatbubbleEllipsesSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaBoxes, FaChartLine, FaMap } from 'react-icons/fa';
import { FaChalkboardUser, FaMoneyCheckDollar } from 'react-icons/fa6';

function SidebarMenu({ collapsed, t }) {
  const navigate = useNavigate();
  const { darkMode,Features } = useSelector((state) => state.user);
  const getItem = (key, label, children, icon) => ({
    key,
    label,
    children,
    icon,
  });
  const items = [
    getItem('', t.dashboard, null, <GrDashboard className="navbar-icon" />),
    getItem(
      'analytics',
      t.VisitAnalysis,
      null,
      <IoAnalyticsOutline className="navbar-icon" />
    ),
    // getItem(
    //   'SalesAnalysis',
    //   t.SalesAnalysis,
    //   null,
    //   <FaMoneyCheckDollar className="navbar-icon" />
    // ),
    // getItem(
    //   'MarketingPromotions',
    //   t.MarketingPromotions,
    //   null,
    //   <FaChartLine className="navbar-icon" />
    // ),
    // getItem(
    //   'InventoryManagement',
    //   t.InventoryManagement,
    //   null,
    //   <FaBoxes className="navbar-icon" />
    // ),
      Features?.includes('Ask Store') && getItem(
          'asknexta',
          t.asknexta,
          null,
          <IoChatbubbleEllipsesSharp  className="navbar-icon" />
        ),
    // getItem(
    //   'heatmap',
    //   'Heatmap',
    //   null,
    //   <FaMap className="navbar-icon" />
    // ),
    // getItem(
    //   'promotin-analytics',
    //   t.promotinAnalytics,
    //   null,
    //   <IoAnalyticsOutline className="navbar-icon" />
    // ),
  ];
  return (
    <div>
      <div
        className="logo"
        onClick={() => {
          navigate('/');
        }}
      >
        <img
          src={darkMode ? logo : logoLight}
          alt="logo"
          style={{
            width: '80%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            cursor: 'pointer',
          }}
        />
      </div>
      <Menu
        mode="inline"
        // mode="horizontal"
        items={items}
        defaultSelectedKeys={[
          window.location.pathname === '/'
            ? ''
            : window.location.pathname.split('/')[1],
        ]}
        defaultOpenKeys={['sub2']}
        onClick={({ key }) => {
          // console.log('getItem  key:', key);
          navigate('/' + key);
        }}
      />
    </div>
  );
}

export default SidebarMenu;
