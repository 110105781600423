import React, { useState } from 'react'
import { queryKeys } from '../../services/react-query/queryKeys';
import { deleteBranch, getBranches } from '../../Network/branches';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DynamicTableAnt from '../../Hooks/DynamicTableAnt';
import Heading from '../../Components/Common/Heading/Heading';
import { App, Button, message, Popconfirm } from 'antd';
import { PlusOutlined,DeleteOutlined,EditOutlined } from '@ant-design/icons';
import { ModalAppBranches } from '../../Components/Common/ModalApp/ModalApp';

const Branches = ({t}) => {
    const [searchText, setSearchText] = useState('');
    const client = useQueryClient();
    const {message} = App.useApp();
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editId, setEditId] = useState(null);
    const { data: branches, isLoading: loadingBranches } = useQuery({
        queryKey: [queryKeys.getBranches],
        queryFn: () => getBranches(),
        keepPreviousData: true,
      });
      const { mutate: deleteBranchMutate } = useMutation({
        mutationKey: ['deleteBranch'],
        mutationFn: (id) => deleteBranch(id),
        onSuccess: (data) => {
          if (data?.message?.status === 500) {
            message.error(data?.message);
            return false;
          }
          client.invalidateQueries({
            queryKey: [queryKeys.getBranches],
          });
          message.success("Branch deleted successfully");
        },
        onError: (error) => {
          message.error(error);
        },
      });
      const columns = [
        {
          title: t.branche,
          dataIndex: 'Location',
          key: 'Location',
          filteredValue: [searchText],
          onFilter: (value, record) => {
            const name = String(record.name).toLowerCase();
            const searchValue = value.toLowerCase();
            return name.includes(searchValue);
          },
        },
        // {
        //   title: t.BrandName,
        //   dataIndex: 'BrandName',
        //   key: 'BrandName',
        // },
        {
          title: t.site,
          dataIndex: 'SiteID',
          key: 'SiteID',
        },
        {
          title: t.TotalCapacity,
          dataIndex: 'TotalCapacity',
          key: 'TotalCapacity',
        },
        {
            title: t.actions,
            key: 'actions',
            width: '150px',
            align: 'center',
            render: (text, record) => (
              <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    size="small"
                    style={{
                      color: '#fff',
                      backgroundColor: '#1890ff',
                      borderColor: '#1890ff',
                    }}
                    onClick={() => {
                      setOpen(true);
                      setEdit(true);
                      setEditId(record?._id);
                    //   console.log(record?._id);
                    }}
                  />
                <Popconfirm
                  // title={t.Doyouwanttodeletethebranch + (record?.Location)}
                  title={t.Doyouwanttodeletethebranch}
                  description={t.areyousure}
                  okText={t.yes}
                  okType="danger"
                  cancelText={t.no}
                  onConfirm={() => deleteBranchMutate(record?._id)}
                >
                  <Button
                    onClick={() => {
                      console.log(record);
                      
                    }}
                    type="primary"
                    icon={<DeleteOutlined />}
                    size="small"
                    danger
                  />
                </Popconfirm>
              </div>
            ),
          },
      ]
  return (
    <>
      <div className="branches">
      <Heading title={t.branches} />
      <div
        style={{
          marginTop: '20px',
        }}
      >
        <DynamicTableAnt
          columns={columns}
          data={branches?.map((branch) => ({
            ...branch,
            key: branch?._id,
            Location: branch?.Location,
            TotalCapacity: (branch?.TotalCapacity || 0),
          }))}
          loading={loadingBranches}
          setSearchText={setSearchText}
          Placeholder="Search Branche"
          buttonModal={
            <Button
              type="primary"
              onClick={() => {
                setOpen(true);
                setEdit(false);
                setEditId("");
              }}
              className="btn-add-admin"
              icon={
                <PlusOutlined
                  style={{
                    fontSize: '10px',
                  }}
                />
              }
            >
              {t.addBranch}
            </Button>
          }
        />
      </div>
      <ModalAppBranches open={open} setOpen={setOpen} edit={edit} setEdit={setEdit} editId={editId} t={t} />
      </div>
    </>
  )
}

export default Branches