import { Button, Result, theme } from "antd"
import { useNavigate } from "react-router"


const NotFoundPage = () => {
    const navigate = useNavigate()
    const { token } = theme.useToken()
  return (
    <div
      className="not-found"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: token.colorBgLayout,
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => navigate('/')}>
            Back Home
          </Button>
        }
      />
    </div>
  );
}

export default NotFoundPage
