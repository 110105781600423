import axios from 'axios';
import { getStoredToken, getStoredUser } from './user-storage';
import { clearUser } from '../Store/user/UserSlice';
import store from '../Store/store';
import { useNavigate } from 'react-router';
console.log(getStoredToken(), "getStoredToken");

export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL: 'https://morrowai-backend-vuc4b3rpsa-uc.a.run.app',
  // timeout: 10000,
  // headers: {
  //   'Content-Type': 'application/json',
  //   withCredentials: true,
  // },
});

axiosInstance.interceptors.request.use(config => {
  // config.headers.Authorization = 'Bearer ' + getStoredToken();
  config.headers.Authorization = 'Basic ' + getStoredToken();
  // config.headers.Authorization = 'Basic' + getStoredToken();
  // if(config?.data instanceof FormData) {
  //   config.headers['Content-Type'] = 'multipart/form-data';
  // }
  return config;
});

axiosInstance.interceptors.response.use(null, err => {
  const statusCode = err.response.status;
  console.log('statusCode', statusCode);
  const navigator = useNavigate();
  if (statusCode === 401) {
    store.dispatch(clearUser());
    navigator(getStoredUser() ? '/admin/login' : '/login');
  }
  else if(statusCode === 403) {
    navigator('/');  
  }
  return err;
});
