import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { notification as AntdNotification  } from "antd";
import { FaBell } from 'react-icons/fa';
const firebaseConfig = {
  apiKey: "AIzaSyAyPCvAEMVSswyETlSjVksuA8WtZQSndP8",
  authDomain: "nexta-b0272.firebaseapp.com",
  projectId: "nexta-b0272",
  storageBucket: "nexta-b0272.appspot.com",
  messagingSenderId: "292879153740",
  appId: "1:292879153740:web:a03f7395cc6f35f35812e9",
  measurementId: "G-Y1REVN8B7L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = () => {
  const token = getToken(messaging, {
    vapidKey: "BEbnixB4rRy0C4u-CuMjHm6KFDHrWJhz3SFR8kzjLwz73F2W1ENHwICf2xCEyleJ2EZ8He8rnR8QIfcGxj8t0cw" ,
  })
  console.log(token, 'token');
  return token 
      .then((currentToken) => {
        if (currentToken) {
          // console.log('current token for client: ', currentToken);
          // Perform any other neccessary action with the token
          return currentToken;
        } else {
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };
  
  export const onMessageListener = (onSuccessfcm) =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        console.log(messaging, 'messagingmessagingmessaging');
        // console.log("onMessageListener", payload)
        // 1 orders
        // 2 comments
        // ReferenceId
        AntdNotification.info({
          message: payload?.notification?.title,
          description: payload?.notification?.body,
          // icon: <FaBell style={{ color: '#6D29F6' }} />,
          icon: payload?.notification?.image ? (
            <img
              src={payload?.notification?.image}
              alt="notification"
              style={{ height: '50px', width: '50px' }}
            />
          ) : (
            <FaBell style={{ color: '#6D29F6' }} />
          ),

        });
        onSuccessfcm(payload);
      });
    });