import "./Heading.scss";
import { IoIosArrowForward } from 'react-icons/io';

const Heading = ({title, t}) => {
  return (
    <div className="heading">
      <h4 className="heading-title">{title}</h4>
      <div className="heading-sub">
        <p className="heading-subtitle">Nexta</p>
        <IoIosArrowForward />
        <p className="heading-subtitle">{title}</p>
      </div>
    </div>
  );
};

export default Heading;
