import { Link } from 'react-router-dom';
// import logo from "../../../assets/images/logo1.png"
// import logo from "../../../assets/images/logo-removebg-preview.png"
import logo from '../../../assets/images/logo-removebg-preview.png';
import './Login.scss';
import { Button, Card, Checkbox, Form, Input, Space, App, Flex } from 'antd';
import React, { useState } from 'react';
import { HiUsers } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { setActiveBrancheId, setActiveBrancheName, setAdmin, setBranches, setFeatures, setImageActiveBranche, setToken, setUser } from '../../../Store/user/UserSlice';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { setStoredToken, setStoredUser } from '../../../services/user-storage';

import { authLogin } from '../../../Network/auth';
const Login = ({ admin }) => {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bodyData, setBodyData] = useState({});
  // console.log('bodyData:', bodyData);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const { mutate: onAuthLogin } = useMutation({
    mutationKey: ['login'],
    mutationFn: (body) => authLogin(body),
    onSuccess: (res) => {
      setLoadingAuth(false);
      console.log('Login  res:', res);
      if(!res){
        return message.error('Please enter the correct email and password. Note that both fields may be case sensitive')
      }
      if (res && res?.reason)
        return message.error(
          res?.reason
          // res?.reason ? res?.reason :
          // 'Please enter the correct email and password. Note that both fields may be case sensitive'
        );

      if (!res.IsSuperUser && admin) {
        message.warning('Please Login From User Panel');
        navigate('/login');
        return;
      }
      if (res?.IsSuperUser && !admin) {
        message.warning('Please Login From Admin Panel');
        navigate('/admin/login');
        return;
      }
      setStoredToken(window.btoa(`${bodyData.email}:${bodyData.password}`));
      dispatch(setToken(window.btoa(`${bodyData.email}:${bodyData.password}`)));
      dispatch(setBranches(res?.Branchs));
      dispatch(setFeatures(res?.Features));
      dispatch(setAdmin(res?.IsSuperUser));
      dispatch(setActiveBrancheId(res?.Branchs[0]?._id));
      dispatch(setActiveBrancheName(res?.Branchs[0]?.SiteID));
      dispatch(setImageActiveBranche(res?.Branchs[0]?.ImagePath));
      setStoredUser(admin);
      // dispatch(setUser(userData));
      // message.success('Login success');
      navigate(admin ? '/admin' : '/');
    },
    onError: (error) => {
      setLoadingAuth(false);
      console.log('Login  error:', error);
      // message.error(error?.reason);
      message.error(
        'Please enter the correct email and password. Note that both fields may be case sensitive'
      );
    },
  });
  const onFinish = (values) => {
    // console.log('Success:', values);
    setLoadingAuth(true);
    setBodyData(values);
    if (admin) {
      onAuthLogin({
        email: values.email,
        password: values.password,
        IsSuperUser: true,
      });
    } else {
      onAuthLogin({
        email: values.email,
        password: values.password,
      });
    }
  };
  return (
    <div
      className="login"
      style={{
        justifyContent: admin ? 'center' : 'space-between',
      }}
    >
      {!admin && (
        <div className="left">
          <img src={logo} alt="logo" />
        </div>
      )}
      <div className="right">
        <Card
          className="login-card"
          style={{
            background: admin ? '#fff' : '#f9f6f6',
          }}
        >
          <Form
            layout="vertical"
            name="login-form"
            onFinish={onFinish}
            form={form}
            className="login-form"
          >
            {admin ? (
              <div style={{ textAlign: 'center' }}>
                <img
                  src={logo}
                  alt="logo"
                  width={220}
                  // height={80}
                  style={{ objectFit: 'contain' }}
                />
              </div>
            ) : (
              <div className="login-title">
                <HiUsers className="login-icon" size={45} />
                <h2 className="login-title-text">Welcome Back !</h2>
              </div>
            )}
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            {/* {!admin && ( */}
              <div className="remember-me">
                <Flex
                  align="center"
                  justify="space-between"
                  style={{
                    marginBottom: '30px',
                  }}
                >
                  {/* <Checkbox className="remember-check">Remember me</Checkbox> */}
                  {admin ? (
                    <Link
                      to="/login"
                      className="forgot-password"
                      style={{ textDecoration: 'underline' }}
                    >
                      Login As User
                    </Link>
                  ) : (
                    <>
                    <Link
                      to="/forgot-password"
                      className="forgot-password"
                      style={{ textDecoration: 'underline' }}
                      >
                      Forgot password?
                    </Link>
                  <a href="mailto:saleh@nexta.sa" className="email-support">
                    Contact support
                  </a>
                      </>
                  )}
                </Flex>
              </div>
            {/* )} */}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                // className="login-form-button"
                loading={loadingAuth}
                block
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;
