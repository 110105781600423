import { axiosInstance } from '../services/axiosInstance';
import catchAsync from '../utils/catchAsync';

// export const getAllUsers = catchAsync(async () => {
//   const { data } = await axiosInstance.get(`/admin/users/`);
//   return data;
// });

export const getUsers = catchAsync(async (pageCount = 1) => {
  const { data } = await axiosInstance.get(`/admin/users/?page=${pageCount}`);
  return data;
});
export const getSingleUser = catchAsync(async (id = 1) => {
  // /admin/users/{user_id}
  const { data } = await axiosInstance.get(`/admin/users/${id}`);
  return data;
});

export const addUser = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/admin/users/`, body);
  return data;
});

export const updateUser = catchAsync(async ({ body, id }) => {
  const { data } = await axiosInstance.put(`/admin/users/${id}`, body);
  return data;
});

export const deleteUser = catchAsync(async (id) => {
  // /admin/users/{user_id}
  const { data } = await axiosInstance.delete(`/admin/users/${id}`);
  return data;
})